import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import useResizeObserver from 'use-resize-observer'

import useNumberOptions from '../../../hooks/useNumberOptions'
import {
  getChildCategoriesRows,
  getChildRecordsRows,
  getHeatmap,
} from '../../../loaders/heatmap'
import { GridCard } from '../../Card/Card'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import { useFilters } from '../../Filters/FilterProvider'
import AgGridReact, { LoadingCellRenderer } from '../../Grid/Grid'
import Page from '../../Page/LegacyPage'
import Spinner from '../../Spinner'
import Button from '../../core/Button/Button'
import { getAutoGroupColumnDef, getColumnDefs, getRowData } from './utils'

const Heatmap = ({
  portfolio,
  noiDefinition,
  buildingFilters,
  timeFilter,
  absolute,
  excludeIncompleteMonths,
  gridRef,
  onRecordRowClick,
}) => {
  const resizeRef = useRef(null)

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        })
      }
    },
  })

  const rowFetcher = useMemo(() => {
    return (params, parentNode) => {
      if (params.groupKeys.length) {
        const categoryId = params.groupKeys.at(-1).split(':')[2]

        const {
          buildingId,
          noiDefinition,
          hasChildCategoriesRows,
          hasChildRecordsRows,
        } = parentNode.data

        if (hasChildRecordsRows) {
          return getChildRecordsRows(
            buildingId,
            categoryId,
            noiDefinition.noiDefinitionId,
            timeFilter,
            absolute,
            excludeIncompleteMonths
          )
        } else if (hasChildCategoriesRows) {
          return getChildCategoriesRows(
            buildingId,
            categoryId,
            noiDefinition.noiDefinitionId,
            timeFilter,
            absolute,
            excludeIncompleteMonths
          )
        }
      }

      return getHeatmap(
        portfolio.portfolioId,
        noiDefinition.noiDefinitionId,
        buildingFilters,
        timeFilter,
        absolute,
        excludeIncompleteMonths
      )
    }
  }, [
    portfolio,
    noiDefinition,
    buildingFilters,
    timeFilter,
    absolute,
    excludeIncompleteMonths,
  ])

  const createServerSideDataSource = useCallback(() => {
    return {
      getRows: async (params) => {
        try {
          const data = await rowFetcher(params.request, params.parentNode)

          params.success({ rowData: getRowData(data) })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          params.fail()
        }
      },
    }
  }, [rowFetcher])

  const refreshCache = (route) => {
    gridRef.current.api.refreshServerSide({
      route: route,
      purge: false,
    })
  }

  useEffect(() => {
    if (gridRef.current?.api) {
      const datasource = createServerSideDataSource()
      gridRef.current.api.setServerSideDatasource(datasource)

      refreshCache([])
    }
  }, [portfolio, noiDefinition, buildingFilters, timeFilter])

  const numberOptions = useNumberOptions()

  const {
    autoGroupColumnDef,
    columnDefs,
    getRowId,
    gridOptions,
    getServerSideGroupKey,
    isServerSideGroup,
  } = useMemo(() => {
    return {
      autoGroupColumnDef: getAutoGroupColumnDef(),
      columnDefs: getColumnDefs(numberOptions),
      getRowId: ({ data }) => data.rowGroupId,
      getServerSideGroupKey: ({ category, buildingReference }) =>
        `${buildingReference}:${category.standard}:${category.categoryId}`,
      isServerSideGroup: (item) => item.group,
      gridOptions: {
        rowClass: 'cell-default',
        rowHeight: 56,
      },
    }
  }, [numberOptions])

  const onRowClicked = (params) => {
    if (
      onRecordRowClick &&
      params.type === 'rowClicked' &&
      params.data.record
    ) {
      onRecordRowClick(params)
    }
  }

  const onGridReady = useCallback((params) => {
    const datasource = createServerSideDataSource()
    params.api.setServerSideDatasource(datasource)
  }, [])

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      })
    }
  }, [])

  return (
    <>
      <ErrorBoundary>
        <GridCard>
          <div ref={resizeRef} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              autoGroupColumnDef={autoGroupColumnDef}
              columnDefs={columnDefs}
              modules={[
                ServerSideRowModelModule,
                RowGroupingModule,
                ExcelExportModule,
              ]}
              rowClass="c-grid-row"
              loadingCellRenderer={LoadingCellRenderer}
              getRowId={getRowId}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onFirstDataRendered={onFirstDataRendered}
              treeData
              animateRows
              isServerSideGroup={isServerSideGroup}
              getServerSideGroupKey={getServerSideGroupKey}
              onGridReady={onGridReady}
              onRowClicked={onRowClicked}
              rowModelType="serverSide"
            />
          </div>
        </GridCard>
      </ErrorBoundary>
    </>
  )
}

Heatmap.propTypes = {
  portfolio: PropTypes.object.isRequired,
  noiDefinition: PropTypes.object.isRequired,
  buildingFilters: PropTypes.object.isRequired,
  timeFilter: PropTypes.object.isRequired,
  absolute: PropTypes.bool.isRequired,
  excludeIncompleteMonths: PropTypes.bool.isRequired,
  gridRef: PropTypes.object.isRequired,
  onRecordRowClick: PropTypes.func,
}

// Expose imperative api on ref to be used in parent component to refresh the selected row
const useSelectedRowCallback = (ref, gridRef) => {
  const [selectedRowNode, setSelectedRowNode] = useState(null)

  useImperativeHandle(ref, () => ({
    refreshSelectedRow: () => {
      if (selectedRowNode) {
        gridRef.current.api.refreshCells({ rowNodes: [selectedRowNode] })
      }
    },
    clearSelectedRow: () => {
      setSelectedRowNode(null)
    },
  }))

  return setSelectedRowNode
}

const HeatmapSection = forwardRef(
  (
    {
      portfolio,
      noiDefinition,
      buildingFilters,
      timeFilter,
      absolute,
      excludeIncompleteMonths,
      onRecordSelected,
    },
    ref
  ) => {
    const { t } = useTranslation(['dashboard'])
    const gridRef = useRef()

    const setSelectedRowNode = useSelectedRowCallback(ref, gridRef)

    const onDownload = useCallback(() => {
      gridRef.current.api.exportDataAsExcel()
    })

    const { attributes } = useFilters()

    const attributesByKey = useMemo(() => {
      if (!attributes) {
        return {}
      }

      return attributes.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
    }, [attributes])

    return (
      <Page.Section id="heatmap">
        <div className="flex justify-between">
          <Page.Section.Title>{t('overview.heatmap.title')}</Page.Section.Title>
          <Button
            onClick={onDownload}
            className="inline-flex justify-center gap-x-2 py-2 px-2"
          >
            <ArrowDownTrayIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
            {t('overview.heatmap.export')}
          </Button>
        </div>
        <Page.Section.Content>
          {attributes ? (
            <Heatmap
              noiDefinition={noiDefinition}
              portfolio={portfolio}
              timeFilter={timeFilter}
              absolute={absolute}
              excludeIncompleteMonths={excludeIncompleteMonths}
              attributes={attributesByKey}
              buildingFilters={buildingFilters}
              gridRef={gridRef}
              onRecordRowClick={({ node, data: { record } }) => {
                setSelectedRowNode(node) // track currently selected record row
                onRecordSelected(record) // notify parent component about the selected record
              }}
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              <Spinner />
            </div>
          )}
        </Page.Section.Content>
      </Page.Section>
    )
  }
)

HeatmapSection.displayName = 'HeatmapSection'

HeatmapSection.propTypes = {
  portfolio: PropTypes.object.isRequired,
  noiDefinition: PropTypes.object.isRequired,
  buildingFilters: PropTypes.object.isRequired,
  absolute: PropTypes.bool.isRequired,
  excludeIncompleteMonths: PropTypes.bool.isRequired,
  timeFilter: PropTypes.object.isRequired,
  onRecordSelected: PropTypes.func,
}

export default HeatmapSection
