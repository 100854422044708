import assert from 'assert'

import BuildingPopover from './BuildingPopover'
import { useFilters } from './FilterProvider'
import TimePopover from './TimePopover'
import ViewPopover from './ViewPopover'

export default function Filters() {
  const context = useFilters()
  assert(context, 'useFilters must be used within a FilterProvider')

  const {
    portfolios,
    attributes,
    noiDefinitions,
    portfolio,
    timeFilter,
    noiDefinition,
    absolute,
    excludeIncompleteMonths,
    buildingFilters,
    setPortfolio,
    setTimeFilter,
    setNoiDefinition,
    setAbsolute,
    setExcludeIncompleteMonths,
    setBuildingFilters,
  } = context

  return (
    <div className="flex justify-end gap-x-4">
      <BuildingPopover
        portfolios={portfolios}
        attributes={attributes}
        portfolio={portfolio}
        setPortfolio={setPortfolio}
        buildingFilters={buildingFilters}
        setBuildingFilters={setBuildingFilters}
      />
      <TimePopover timeFilter={timeFilter} setTimeFilter={setTimeFilter} />
      <ViewPopover
        noiDefinitions={noiDefinitions}
        noiDefinition={noiDefinition}
        setNoiDefinition={setNoiDefinition}
        absolute={absolute}
        setAbsolute={setAbsolute}
        excludeIncompleteMonths={excludeIncompleteMonths}
        setExcludeIncompleteMonths={setExcludeIncompleteMonths}
      />
    </div>
  )
}
