import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from '../../components/core/Button/Button'

export const FirstName = {
  headerName: 'First Name',
  field: 'firstName',
  width: 100,
  resizable: true,
}

export const LastName = {
  headerName: 'Last Name',
  field: 'lastName',
  width: 100,
  resizable: true,
}

export const Email = {
  headerName: 'Email',
  field: 'email',
  width: 250,
  resizable: true,
}

export const Role = {
  headerName: 'Role',
  field: 'role',
  width: 100,
}

export const Actions = {
  headerName: '',
  field: 'actions',
  width: 40,
  cellRenderer: ({ data: user }) => {
    const { t } = useTranslation(['dashboard'])

    return (
      <div className="h-full flex justify-center items-center">
        <Button
          id={`edit-user-${user.userId}`}
          as={Link}
          to={`/admin/users/${user.userId}/edit`}
        >
          {t('users.editUser')}
        </Button>
      </div>
    )
  },
}
