import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { createContext, useContext, useState } from 'react'

import { getAttributes } from '../../loaders/attributes'
import { getNoiDefinitions } from '../../loaders/noiDefinitions'
import { getAllPortfolios } from '../../loaders/portfolios'

const FilterContext = createContext()

export function useFilters() {
  return useContext(FilterContext)
}

function FilterProvider({ children }) {
  const [portfolio, setPortfolio] = useState(null)
  const [timeFilter, setTimeFilter] = useState(null)
  const [noiDefinition, setNoiDefinition] = useState(null)

  const [absolute, setAbsolute] = useState(true)
  const [excludeIncompleteMonths, setExcludeIncompleteMonths] = useState(true)
  const [buildingFilters, setBuildingFilters] = useState({})

  const { data: portfolios } = useQuery(['portfolios'], getAllPortfolios)
  const { data: attributes } = useQuery(['attributes'], getAttributes)
  const { data: noiDefinitions } = useQuery(
    ['noiDefinitions'],
    getNoiDefinitions
  )

  return (
    <FilterContext.Provider
      value={{
        portfolios,
        attributes,
        noiDefinitions,
        portfolio,
        timeFilter,
        noiDefinition,
        absolute,
        excludeIncompleteMonths,
        buildingFilters,
        setPortfolio,
        setTimeFilter,
        setNoiDefinition,
        setAbsolute,
        setExcludeIncompleteMonths,
        setBuildingFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

FilterProvider.propTypes = {
  children: PropTypes.node,
}

export default FilterProvider
