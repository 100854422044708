import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import Spinner from '../../Spinner'
import Popover, { PopoverSection, PopoverSections } from '../../core/Popover'
import AttributeFilters from './AttributeFilters'
import PortfolioSelector from './PortfolioSelector'

function BuildingPopover({
  portfolios,
  attributes,
  portfolio,
  setPortfolio,
  buildingFilters,
  setBuildingFilters,
}) {
  const [label, setLabel] = useState('')
  const [attributeCount, setAttributeCount] = useState(0)

  // Set the label if the portfolio changes
  useEffect(() => {
    if (portfolio) {
      setLabel(portfolio.name)
    }
  }, [portfolio])

  // Set the first portfolio if none selected
  useEffect(() => {
    if (portfolios && portfolios.length && !portfolio) {
      setPortfolio(portfolios[0])
    }
  }, [portfolios])

  // Set the initial filters if none selected
  useEffect(() => {
    if (attributes && attributes.length && _.isEmpty(buildingFilters)) {
      const initialFilters = attributes.reduce((acc, curr) => {
        acc[curr.id] = curr.options[0]
        return acc
      }, {})

      setBuildingFilters(initialFilters)
    }
  }, [attributes])

  // Set the badge count of specified filters
  useEffect(() => {
    if (buildingFilters) {
      const count = Object.values(buildingFilters).filter(
        ({ id }) => id !== 'all'
      ).length
      setAttributeCount(count)
    }
  }, [buildingFilters])

  return (
    <Popover
      text={label}
      icon={<BuildingOfficeIcon aria-hidden="true" className="h-5 w-5" />}
      badgeCount={attributeCount}
    >
      <PopoverSections>
        {portfolios && attributes ? (
          <>
            <PopoverSection>
              <PortfolioSelector
                portfolios={portfolios}
                selected={portfolio}
                onChange={setPortfolio}
              />
            </PopoverSection>
            <PopoverSection>
              <AttributeFilters
                attributes={attributes}
                selection={buildingFilters}
                onChange={setBuildingFilters}
              />
            </PopoverSection>
          </>
        ) : (
          <PopoverSection>
            <Spinner />
          </PopoverSection>
        )}
      </PopoverSections>
    </Popover>
  )
}

BuildingPopover.propTypes = {
  portfolios: PropTypes.array,
  attributes: PropTypes.array,
  portfolio: PropTypes.object,
  setPortfolio: PropTypes.func,
  buildingFilters: PropTypes.object,
  setBuildingFilters: PropTypes.func,
}

export default BuildingPopover
