import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import Button from '../core/Button/Button'

const RootErrorBoundary = () => {
  return (
    <section className="flex items-center h-full sm:p-16 bg-green selection:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
        <ExclamationCircleIcon className="w-40 h-40 text-gray-600" />
        <p className="text-3xl">
          Looks like our services are currently offline
        </p>
        <Button as="a" rel="noopener noreferrer" href="#" type={null}>
          Back to homepage
        </Button>
      </div>
    </section>
  )
}

export default RootErrorBoundary
