import { useQuery } from '@tanstack/react-query'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useNumberOptions from '../../hooks/useNumberOptions'
import { getOverview } from '../../loaders/overview'
import { format } from '../../utils/number'
import Card from '../Card/Card'
import Spinner from '../Spinner'

const stats = [
  { id: 'actual', label: 'overview.overview.stats.actual', diff: true },
  { id: 'budget', label: 'overview.overview.stats.budget' },
  { id: 'underwriting', label: 'overview.overview.stats.underwriting' },
]

const Diff = ({ value, numberOptions }) => {
  const isPositive = value > 0

  if (!value) {
    return null
  }

  const number = format(value, {
    ...numberOptions,
    signDisplay: 'exceptZero',
  })

  return (
    <span
      className={classnames('text-base ml-1', {
        'text-green-500': isPositive,
        'text-red-500': !isPositive,
      })}
    >
      {number}
    </span>
  )
}

Diff.propTypes = {
  value: PropTypes.number.isRequired,
  numberOptions: PropTypes.object,
}

const Overview = ({
  portfolio,
  noiDefinition,
  buildingFilters,
  timeFilter,
  absolute,
  excludeIncompleteMonths,
}) => {
  const { t } = useTranslation(['dashboard'])

  const portfolioId = portfolio?.portfolioId
  const noiDefinitionId = noiDefinition?.noiDefinitionId

  const { data, isLoading, error } = useQuery({
    enabled: !!portfolio,
    queryKey: [
      'overview',
      portfolioId,
      noiDefinitionId,
      buildingFilters,
      timeFilter,
      absolute,
      excludeIncompleteMonths,
    ],
    queryFn: () =>
      getOverview(
        portfolioId,
        noiDefinitionId,
        buildingFilters,
        timeFilter,
        absolute,
        excludeIncompleteMonths
      ),
  })

  const numberOptions = useNumberOptions()

  return (
    <>
      {isLoading || error ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <Card as="dl" className="flex justify-around">
            {stats.map((item) => (
              <div key={item.id}>
                <dt className="text-3xl prop-numeric">
                  {format(data[item.id], numberOptions)}
                  {item.diff && data.budgetDiff && (
                    <Diff
                      value={data.budgetDiff}
                      numberOptions={numberOptions}
                    />
                  )}
                </dt>
                <dd className="text-base text-ps-500">{t(item.label)}</dd>
              </div>
            ))}
          </Card>
        </div>
      )}
    </>
  )
}

Overview.propTypes = {
  portfolio: PropTypes.object.isRequired,
  noiDefinition: PropTypes.object.isRequired,
  buildingFilters: PropTypes.object,
  timeFilter: PropTypes.object.isRequired,
  absolute: PropTypes.bool.isRequired,
  excludeIncompleteMonths: PropTypes.bool.isRequired,
}

export default Overview
