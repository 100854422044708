import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { createContext } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAllTimeFilters } from '../../loaders/result'

export const TimeFilterContext = createContext()

const PERIOD_TYPES = ['year', 'quarter', 'month']

const QUARTERS = ['q1', 'q2', 'q3', 'q4']

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

function getTranslatedPeriodTypes(t) {
  return Object.fromEntries(
    PERIOD_TYPES.map((key) => [key, t(`timeFilters.period.${key}`)])
  )
}

function getTranslatedPeriods(periodType, t) {
  switch (periodType) {
    case 'quarter':
      return QUARTERS.map((quarter) =>
        t(`timeFilters.quarter.${quarter.toLowerCase()}`)
      )
    case 'month':
      return MONTHS.map((month) =>
        t(`timeFilters.month.${month.toLowerCase()}`)
      )
    default:
      return []
  }
}

export function formatTimeFilter(
  { year, periodType, startPeriod, endPeriod },
  { periods }
) {
  switch (periodType) {
    case 'year':
      return `${year}`
    case 'quarter':
      return `${year} (${periods[startPeriod]} - ${periods[endPeriod]})`
    case 'month':
      return `${year} (${periods[startPeriod]} - ${periods[endPeriod]})`
  }
}

function getDefaultPeriods(periodType) {
  const month = new Date().getMonth()

  // Default end to previous period
  if (periodType === 'quarter') {
    const quarter = Math.floor(month / 3)
    return [0, Math.max(0, quarter - 1)]
  } else if (periodType === 'month') {
    return [0, Math.max(0, month - 1)]
  } else {
    return [null, null]
  }
}

// Return min and max months in range 1-12
function getMinAndMaxMonths(periodType, startPeriod, endPeriod) {
  switch (periodType) {
    case 'quarter':
      return [startPeriod * 3 + 1, (endPeriod + 1) * 3]
    case 'month':
      return [startPeriod + 1, endPeriod + 1]
    default:
      return []
  }
}

const TimeFilterProvider = ({ selected, onChange, children }) => {
  const { t } = useTranslation(['dashboard'])

  const initialYear = selected ? selected.year : null

  const [year, setYear] = useState(initialYear)
  const [periodType, setPeriodType] = useState('year')
  const [startPeriod, setStartPeriod] = useState(null)
  const [endPeriod, setEndPeriod] = useState(null)

  const periodTypes = useMemo(() => getTranslatedPeriodTypes(t), [])
  const periods = useMemo(
    () => getTranslatedPeriods(periodType, t),
    [periodType]
  )

  // Download time filters
  const { data: timeFilters, isLoading } = useQuery({
    queryKey: ['timeFilters'],
    queryFn: () => getAllTimeFilters({ includeAllOption: false }),
    staleTime: 2000,
  })

  // Set the inital year to the first time filter year
  useEffect(() => {
    if (timeFilters && timeFilters.length > 0 && !selected) {
      const { year } = timeFilters[0]
      setYear(year)
    }
  }, [timeFilters])

  // Always provide translated text
  const formattedText = useMemo(() => {
    return formatTimeFilter(
      {
        year,
        periodType,
        startPeriod,
        endPeriod,
      },
      { periods }
    )
  }, [year, periodType, startPeriod, endPeriod])

  // Call onChange when selection changes
  useEffect(() => {
    if (!year) {
      return
    }

    const [minMonth, maxMonth] = getMinAndMaxMonths(
      periodType,
      startPeriod,
      endPeriod
    )
    const timeFilter = {
      year,
      minMonth,
      maxMonth,
    }

    onChange(timeFilter, formattedText)
  }, [year, startPeriod, endPeriod])

  // Reset start/end periods to span full year if the period type changes
  useEffect(() => {
    const [start, end] = getDefaultPeriods(periodType)
    setStartPeriod(start)
    setEndPeriod(end)
  }, [periodType])

  // Reset end period if before start period, since it is invalid
  useEffect(() => {
    if (startPeriod !== null && endPeriod !== null && endPeriod < startPeriod) {
      setEndPeriod(startPeriod)
    }
  }, [startPeriod, endPeriod])

  const value = useMemo(
    () => ({
      year,
      periodType,
      startPeriod,
      endPeriod,
      formattedText,
      setYear,
      setPeriodType,
      setStartPeriod,
      setEndPeriod,
      timeFilters,
      periodTypes,
      periods,
      isLoading,
    }),
    [
      year,
      periodType,
      startPeriod,
      endPeriod,
      formattedText,
      setYear,
      setPeriodType,
      setStartPeriod,
      setEndPeriod,
      timeFilters,
      periodTypes,
      periods,
      isLoading,
    ]
  )

  return (
    <TimeFilterContext.Provider value={value}>
      {children}
    </TimeFilterContext.Provider>
  )
}

TimeFilterProvider.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.object,
  onChange: PropTypes.func,
}

export default TimeFilterProvider
