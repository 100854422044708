import classnames from 'classnames'
import React, { ComponentPropsWithoutRef } from 'react'

const styles = {
  variant: {
    primary:
      'border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50',
    neutral: 'border-gray-300 text-gray-700 shadow-sm hover:bg-gray-50',
    negative:
      'border-red-600 bg-red-100 text-gray-700 shadow-sm hover:bg-red-50',
  },
  size: {
    md: 'px-4 py-2 text-sm font-medium',
  },
}

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  as?: React.ElementType
  isFull?: boolean
  children: React.ReactNode
  variant?: 'primary' | 'negative' | 'neutral'
  size?: 'md'
  className?: string
}

export const Button = ({
  as: Component = 'button',
  isFull,
  children,
  variant = 'primary',
  size = 'md',
  className,
  ...props
}: ButtonProps) => {
  return (
    <Component
      type="button"
      className={classnames(
        'inline-flex items-center rounded-md border',
        'focus:outline-none focus:ring-2 focus:ring-ps-500 focus:ring-offset-2',
        styles.variant[variant],
        styles.size[size],
        className,
        {
          'w-full justify-center': isFull,
        }
      )}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Button
