import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Checked from '../components/Checked'
import Filters from '../components/Filters'
import FilterProvider, {
  useFilters,
} from '../components/Filters/FilterProvider'
import { Content, Header, Page, Section, Title } from '../components/Page/Page'
import RecordDetail from '../components/RecordDetail/RecordDetail'
import Heatmap from '../components/Result/Heatmap/Heatmap'
import Overview from '../components/Result/Overview'
import Drawer from '../components/core/Drawer/Drawer'

const Result = () => {
  const { t } = useTranslation(['dashboard'])

  const [selectedRecord, setSelectedRecord] = useState(null)

  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    setOpenDrawer(!!selectedRecord)
  }, [selectedRecord])

  const {
    portfolio,
    timeFilter,
    noiDefinition,
    absolute,
    excludeIncompleteMonths,
    buildingFilters,
  } = useFilters()

  const heatmapRef = useRef()

  const onRecordUpdate = () => {
    heatmapRef.current?.refreshSelectedRow()
  }

  const onDrawerClosed = () => {
    setSelectedRecord(null)
    heatmapRef.current?.clearSelectedRow()
  }

  return (
    <Checked right="result:read">
      <Drawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        onClosed={onDrawerClosed}
        title={selectedRecord ? selectedRecord.text : ''}
        content={
          selectedRecord ? (
            <RecordDetail record={selectedRecord} onUpdate={onRecordUpdate} />
          ) : null
        }
      />
      <Page>
        <Header actions={<Filters />}>{t('overview.title')}</Header>
        <Section id="overview">
          <Title>
            {t('overview.overview.title')}
            {absolute ? '' : ` (${t('overview.overview.per_m2')})`}
          </Title>
          <Content>
            {!!portfolio && !!timeFilter && !!noiDefinition && (
              <Overview
                portfolio={portfolio}
                noiDefinition={noiDefinition}
                buildingFilters={buildingFilters}
                timeFilter={timeFilter}
                absolute={absolute}
                excludeIncompleteMonths={excludeIncompleteMonths}
              />
            )}
          </Content>
        </Section>
        <Fragment>
          {!!portfolio && !!timeFilter && !!noiDefinition && (
            <Heatmap
              ref={heatmapRef}
              portfolio={portfolio}
              noiDefinition={noiDefinition}
              buildingFilters={buildingFilters}
              timeFilter={timeFilter}
              absolute={absolute}
              excludeIncompleteMonths={excludeIncompleteMonths}
              onRecordSelected={setSelectedRecord}
            />
          )}
        </Fragment>
      </Page>
    </Checked>
  )
}

const FilterableResult = () => {
  return (
    <FilterProvider>
      <Result />
    </FilterProvider>
  )
}

export default FilterableResult
