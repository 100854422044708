import { Listbox as HeadlessListbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classnames from 'classnames'
import { Fragment, PropsWithChildren } from 'react'

export const Listbox = HeadlessListbox

interface ListboxLabelProps {
  className?: string
}

export const ListboxLabel = ({ className, ...props }: ListboxLabelProps) => (
  <HeadlessListbox.Label
    className={classnames('block font-medium text-gray-900 mb-2', className)}
    {...props}
  />
)

export const ListboxButton = ({ children }: PropsWithChildren) => (
  <HeadlessListbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-ps-500 focus:outline-none focus:ring-1 focus:ring-ps-500 sm:text-sm">
    <span className="flex items-center">
      <span className="block truncate">{children}</span>
    </span>
    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </span>
  </HeadlessListbox.Button>
)

interface ListboxOptionsProps {
  isOpen: boolean
  className?: string
}

export const ListboxOptions = ({
  isOpen,
  className,
  ...props
}: PropsWithChildren<ListboxOptionsProps>) => (
  <Transition
    show={isOpen}
    as={Fragment}
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <HeadlessListbox.Options
      className={classnames(
        'absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
        className
      )}
      {...props}
    />
  </Transition>
)

interface ListboxOptionProps {
  className?: string
  value: unknown
}

export const ListboxOption = ({
  className,
  value,
  ...props
}: PropsWithChildren<ListboxOptionProps>) => (
  <HeadlessListbox.Option
    className={({ active }) =>
      classnames(
        'relative cursor-default select-none py-2 pl-3 pr-9',
        className,
        {
          'text-white bg-ps-600': active,
          'text-gray-900': !active,
        }
      )
    }
    value={value}
    {...props}
  />
)
