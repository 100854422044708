import classnames from 'classnames'
import { ComponentPropsWithoutRef } from 'react'

import useNumberOptions from '../../../hooks/useNumberOptions'
import { format } from '../../../utils/number'
import './Number.css'

interface NumberProps extends ComponentPropsWithoutRef<'span'> {
  value?: number
  className?: string
}

const Number = ({ value, className }: NumberProps) => {
  const numberOptions = useNumberOptions()
  return (
    <span className={classnames('prop-number', className)}>
      {format(value ?? 0, numberOptions)}
    </span>
  )
}

export default Number
