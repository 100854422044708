import { Navigate, Outlet } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

const ProtectedRoute = () => {
  const auth = useAuth()

  if (!auth) {
    return <Navigate to="/login" />
  }

  return <Outlet />
}

export default ProtectedRoute
