import _ from 'lodash'

interface Options {
  signDisplay?: 'auto' | 'always' | 'exceptZero' | 'negative' | 'never'
  locale?: string
}

export const format = (
  number: number | null,
  { signDisplay, locale }: Options = {}
) => {
  if (number == null) {
    return number
  }

  if (!locale) {
    locale = navigator.language
  }

  return new Intl.NumberFormat(locale, {
    signDisplay,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  }).format(number)
}

export const getGridValueFormatter = (field: string, options: Options = {}) => {
  return function (params: Record<string, unknown>) {
    const value = _.get(params, ['data', field])

    return format(value, options)
  }
}
