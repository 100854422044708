import { useMemo } from 'react'

import useSettings from './useSettings'

export default function useNumberOptions() {
  const { settings } = useSettings()

  return useMemo(() => {
    return {
      locale: settings?.numberLocale,
    }
  }, [settings])
}
