import classNames from 'classnames'
import {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
} from 'react'

import * as Typography from '../core/Typography'

interface HeaderProps extends ComponentPropsWithoutRef<'header'> {
  actions?: ReactNode
  subtitle?: string
}

export const Header = ({ actions, subtitle, children }: HeaderProps) => {
  return (
    <header>
      <div className="mx-auto max-w-7xl flex">
        <div className="flex-1">
          <Typography.H1>{children}</Typography.H1>
          {subtitle && (
            <p className="mt-2 text-lg font-light text-ps-500">{subtitle}</p>
          )}
        </div>
        {actions && <div>{actions}</div>}
      </div>
    </header>
  )
}

export const Section = (props: ComponentPropsWithoutRef<'section'>) => (
  <section className="mx-auto max-w-7xl my-16" {...props} />
)

export const Title = ({ children }: ComponentPropsWithoutRef<'h3'>) => (
  <Typography.H3>{children}</Typography.H3>
)

export const Content = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={classNames('my-6', className)} {...props} />
  )
)

Content.displayName = 'Content'

export const Page = ({ children }: ComponentPropsWithoutRef<'main'>) => {
  return <main className="min-w-0 flex-1 overflow-y-auto">{children}</main>
}
