import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import AuthContext from '../../contexts/AuthContext'

const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorageState('token')
  const [user, setUser] = useLocalStorageState('user')
  const [rights, setRights] = useLocalStorageState('rights', {
    defaultValue: [],
  })
  const [isAuthenticated, setIsAuthenticated] = useState(!!token)

  const login = useCallback(({ token, user, rights }, callback) => {
    setToken(token)
    setUser(user)
    setRights(rights)
    setIsAuthenticated(true)

    if (callback) {
      callback()
    }
  }, [])

  const logout = useCallback((callback) => {
    setToken(null)
    setUser(null)
    setRights([])
    setIsAuthenticated(false)

    if (callback) {
      callback()
    }
  }, [])

  const value = useMemo(
    () => ({ user, token, rights, isAuthenticated, login, logout }),
    [user, token, rights, isAuthenticated, login, logout]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export default AuthProvider
